import RouteConfig from 'src/config/RouteConfig';
import { AuthenticationContextType } from 'src/providers/AuthenticationContext';
import { Location, matchRoutes, NavigateFunction } from 'react-router-dom';
import { CacheContextType } from 'src/providers/CacheContext';
import { ObjectUtil, StringUtil, UrlUtil } from 'src/utils';

/**
 * A service to handle reusable function in the login flow (so far just one)
 */
class LoginService {
  /**
   * Navigates to the next route, setting account/location and skipping those pages based on business logic
   */
  public async GetRouteWithSetAccountLocation(authContext: AuthenticationContextType, cacheContext: CacheContextType, browserLocation: Location) {
    // Start by loading stuff since we are in an async context
    const auth = await authContext.getAuth();

    if (auth == null) {
      console.log('[NavigateToNextRoute] No user auth detected, sending to login page');
      return authContext.userRole === 'agent' ? RouteConfig.LOGIN_AGENT() : RouteConfig.LOGIN_EMAIL();
    }

    if (!auth.userActivated) {
      console.log('[NavigateToNextRoute] User not activated');
      return RouteConfig.LOGIN_ACTIVATE();
    }

    // Dev Note: Might remove this and move that navigation to the activate page
    // If the user activates, they will be on the activate page and need to be sent to the confirmation page
    if (auth.userActivated && browserLocation.pathname === RouteConfig.LOGIN_ACTIVATE()) {
      console.log('[NavigateToNextRoute] Activation success');
      return RouteConfig.LOGIN_ACTIVATE_SUCCESS();
    }

    // If the user is going through the password reset pages, then we will want to ignore them. Otherwise the onLoad logic will get 'very excited'
    // Removed ACTIVATE_SUCCESS so the 'Continue' button works on that page
    if (browserLocation.pathname === RouteConfig.LOGIN_FORGOT_PASSWORD_STEP_1()
      || browserLocation.pathname === RouteConfig.LOGIN_FORGOT_PASSWORD_STEP_2()
      || browserLocation.pathname === RouteConfig.LOGIN_FORGOT_PASSWORD_STEP_3()) {
      console.log('[NavigateToNextRoute] User is within the protected, but not easily navigatable pages');
      return browserLocation.pathname;
    }

    if (auth.tfaEnabled && !auth.tfaVerified) {
      console.log('[NavigateToNextRoute] TFA required');
      return RouteConfig.LOGIN_TWO_FACTOR();
    }

    if (!auth.authenticated) {
      console.log('[NavigateToNextRoute] User has made it to the password page');
      return RouteConfig.LOGIN_PASSWORD();
    }

    // We are authed now which only leaves the account and location pages
    const accountResults = await cacheContext.getAccounts();
    let selectedAccount = await authContext.getAccount();
    let selectedLocation = await authContext.getLocation();
    let returnUrl = await authContext.getReturnUrl();

    if (!ObjectUtil.IsNullOrEmpty(returnUrl)) {
      console.log('[NavigateToNextRoute] has return url', returnUrl);
      const queryParams = new URLSearchParams(returnUrl.search);
      const routeId = queryParams.get('route_id');

      if (!ObjectUtil.IsNullOrEmpty(routeId)) {
        // Looks like we have a routeId! These can come from anywhere, so load up the accounts/locations from our account, setting where we match
        try{
          const { accountId, locationId } = UrlUtil.ParseRouteId(routeId);
          const foundAccount = accountResults.find(x => x.id === accountId);
          if (foundAccount != null) {
            selectedAccount = foundAccount;
            authContext.updateAccount(selectedAccount);

            const locationResults = await cacheContext.getLocations(selectedAccount.id);
            const foundLocation = locationResults.find(x => x.id === locationId);
            if (foundLocation != null) {
              selectedLocation = foundLocation;
              authContext.updateLocation(selectedLocation);
            }
          }
        }
        catch(error){
          //routeId is probably fubar
          authContext.updateReturnUrl(null);
        }
        
      }

      // One way or another, we need to clear our the redirectUrl as it will cause problems when trying to change account/location otherwise
      authContext.updateReturnUrl(null);
    }

    // Get a selected account or make the user do it
    if (selectedAccount == null) {
      if(authContext.userRole === 'agent') //If selectedaccount is null here then something has gone wrong proabably the user navigated during the login process
      {
          console.log(`[NavigateToNextRoute] Agent with no account selected. Navigating to login`);
          authContext.logout();
          return RouteConfig.LOGIN_AGENT();
      }
      if (accountResults.length === 1) {
        selectedAccount = accountResults[0];
        authContext.updateAccount(selectedAccount);
      } else {
        // User must select an account
        console.log(`[NavigateToNextRoute] ${accountResults.length} accounts. Navigating to account selection`);
        return RouteConfig.LOGIN_ACCOUNT_SELECTION();
      }
    }

    // Do the same for the location
    const locationResults = await cacheContext.getLocations(selectedAccount.id);
    if (selectedLocation == null) {
      if (locationResults.length === 1) {
        selectedLocation = locationResults[0];
        authContext.updateLocation(selectedLocation);
      } else {
        // User must select a location
        console.log(`[NavigateToNextRoute] ${locationResults.length} accounts. Navigating to location selection`);
        return RouteConfig.LOGIN_LOCATION_SELECTION();
      }
    }

    // Both are selected, so we can move to the home page
    return RouteConfig.HOME();
  }
}

export default new LoginService();
